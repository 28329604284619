import { useEffect, useState } from 'react';
import { CloudTransactionResponse } from './cloud-transaction';
import { SpiCloudPairing } from '../redux/reducers/PairingSlice/interfaces';
import { createSignedRequestInit } from '../utils/common/signRequest';

interface Props {
  pairing: SpiCloudPairing;
  transactionId: string;
}

interface FetchCloudTransaction {
  isError: boolean;
  isLoading: boolean;
  transactionResponse?: CloudTransactionResponse;
}

export function useFetchCloudTransaction({ pairing, transactionId }: Props): FetchCloudTransaction {
  const [isError, setIsError] = useState(false);
  const [transactionResponse, setTransactionResponse] = useState<CloudTransactionResponse | undefined>();

  useEffect(() => {
    async function fetchTransaction() {
      const { keyId, signingSecret, spiCloudApiBaseUrl } = pairing;
      const url = `${spiCloudApiBaseUrl}/api/v1/transactions/${transactionId}`;
      const signedRequest = await createSignedRequestInit(keyId, signingSecret, url);
      const response = await fetch(url, signedRequest);

      if (!response.ok) {
        setIsError(true);
        return;
      }

      const payload = await response.json();

      if (payload?.error) {
        setIsError(true);
        return;
      }

      setTransactionResponse(payload);
    }

    fetchTransaction();
  }, []);

  const isLoading = !transactionResponse && !isError;

  return { isError, isLoading, transactionResponse };
}
