import { CloudTransactionResponse } from '../transaction-handling/cloud-transaction';

export interface CloudTransactionLog {
  [id: string]: CloudTransactionLogEntry;
}

export interface CloudTransactionLogEntry extends CloudTransactionLogEntryOptions {
  // TODO: FE-98 - Decide what props we need to display for cloud transactions
  id: string;
}

export interface CloudTransactionLogEntryOptions {
  isUnknown: boolean;
}

export const STORAGE_KEY = 'CloudTransactionLog';

export function getCloudTransactionLog(): CloudTransactionLog {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '{}');
}

export function addCloudTransactionLogEntry(
  transactionResponse: CloudTransactionResponse,
  options: CloudTransactionLogEntryOptions
): void {
  const transactionId = transactionResponse.data?.id;

  if (!transactionId) {
    throw new Error('Cannot add a cloud transaction log entry without a transaction id');
  }

  const log = getCloudTransactionLog();
  const entry = {
    ...options,
    id: transactionId,
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...log, [transactionId]: entry }));
}

export function getCloudTransactionLogEntry(transactionId: string): CloudTransactionLogEntry | undefined {
  return getCloudTransactionLog()[transactionId];
}
