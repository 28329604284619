import { useMemo } from 'react';
import useStyles from './spiCloudPairingIcon.styles';

export interface SpiCloudPairingIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  pairingId?: string;
  hexCode?: string;
}

export function SpiCloudPairingIcon({ hexCode, pairingId, ...props }: SpiCloudPairingIconProps) {
  const classes = useStyles();

  const backgroundColor = useMemo(() => {
    if (hexCode) {
      return hexCode;
    }
    if (pairingId) {
      return `#${pairingId.slice(-6)}`;
    }
    return 'transparent';
  }, [hexCode, pairingId]);

  return <span {...props} className={classes.icon} style={{ ...props.style, backgroundColor }} />;
}
