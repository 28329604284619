import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const pairingsMap = (state: RootState) => {
  const { _persist, ...pairings } = state.pairings;
  return pairings;
};

export const selectCloudPairings = createSelector(pairingsMap, (pairings) => Object.values(pairings));

export const selectHasCloudPairings = createSelector(selectCloudPairings, (pairings) => pairings.length > 0);

export const selectCloudPairing = (id: string) =>
  createSelector(pairingsMap, (pairings) => {
    // The pairingMap type assumes that any string key is a valid pairing
    // this isn't true when doing a lookup, so the easiest way to handle this is to
    // check for the key in the object
    if (id in pairings) {
      return pairings[id];
    }
    return undefined;
  });
