import { createSlice } from '@reduxjs/toolkit';
import { SpiCloudSettlement, SpiCloudSettlementState } from './interfaces';

const cloudSettlementSlice = createSlice({
  name: 'cloudSettlement',
  initialState: {},
  reducers: {
    reset() {
      return {};
    },
    addSettlement(state: SpiCloudSettlementState, action: { payload: SpiCloudSettlement }) {
      state[action.payload.id] = action.payload;
    },
    removeSettlement(state: SpiCloudSettlementState, action: { payload: string }) {
      delete state[action.payload];
    },
    updateSettlement(
      state: SpiCloudSettlementState,
      action: { payload: Pick<SpiCloudSettlement, 'pairingId'> & Partial<Omit<SpiCloudSettlement, 'pairingId'>> }
    ) {
      state[action.payload.pairingId] = {
        ...state[action.payload.pairingId],
        ...action.payload,
      };
    },
  },
});

export const { reset, addSettlement, removeSettlement, updateSettlement } = cloudSettlementSlice.actions;

export default cloudSettlementSlice.reducer;
