import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../Layout';
import { CloudPaymentSummary } from '../CloudPaymentSummary/CloudPaymentSummary';
import { selectCloudPairing } from '../../redux/reducers/PairingSlice/pairingSelectors';
import { useFetchCloudTransaction } from '../../transaction-handling/use-fetch-cloud-transaction';
import { clearAllProducts } from '../../redux/reducers/ProductSlice/productSlice';
import { SpiCloudPairing } from '../../redux/reducers/PairingSlice/interfaces';

function CloudOrderFinishedDetails({ pairing, transactionId }: { pairing: SpiCloudPairing; transactionId: string }) {
  const { isError, isLoading, transactionResponse } = useFetchCloudTransaction({ pairing, transactionId });

  if (isError) {
    return <div>Could not load transaction.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <CloudPaymentSummary pairing={pairing} transactionResponse={transactionResponse} />;
}

interface Params {
  pairingId: string;
  transactionId: string;
}

export function CloudOrderFinished() {
  const { pairingId, transactionId } = useParams<Params>();
  const pairing = useSelector(selectCloudPairing(pairingId));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAllProducts());
  }, [dispatch]);

  return (
    <Layout>
      {pairing ? (
        <CloudOrderFinishedDetails pairing={pairing} transactionId={transactionId} />
      ) : (
        <div>Could not load pairing.</div>
      )}
    </Layout>
  );
}
